<template>
  <div class="mb-1 allocate-form-outer" v-if="$route.name === 'edit-operator'">
    <validation-observer ref="unitAllocationRules" tag="form">
      <b-row class="unit-allocate-form align-items-center">
        <b-col
          cols="12"
          md="3"
          class="select-unit-un modal-view new-date-picker-25"
        >
          <b-form-group :label="$t('operators.StartDate')" class="date-mb">
            <validation-provider
              #default="{ errors }"
              name="Start Date"
              vid="Start Date"
              :rules="{
                required: true
              }"
              ref="clearErrorMessage"
            >
              <DateTimePicker
                v-model="start_date"
                :placeholder="$t('operators.EnterStartDate')"
                :isEditable="true"
                :isMinDate="true"
              />

              <small class="text-danger">{{
                errors[0] ? handleError(errors[0]) : ""
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="select-unit-un modal-view new-date-picker-25"
        >
          <b-form-group :label="$t('operators.EndDate')" class="date-mb">
            <DateTimePicker
              v-model="end_date"
              :placeholder="$t('operators.EnterEndDate')"
              :isEditable="true"
              :isMinDate="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="select-unit-un modal-view">
          <b-form-group :label="$t('operators.Unit')">
            <validation-provider
              #default="{ errors }"
              name="Unit"
              vid="Unit"
              class="selectunittext"
              :rules="{
                required: true
              }"
              ref="clearErrorMessage"
            >
              <v-select
                :placeholder="$t('operators.SelectUnit')"
                ref="unitSelect"
                v-model="unit_id"
                label="name"
                :options="unitList"
                :reduce="(option) => option.id"
                class="min-100-per"
                :clearable="false"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{
                errors[0] ? handleError(errors[0]) : ""
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="4" md="2" class="download-btn-outer" v-if="isCreate">
          <b-button
            :title="$t('operators.Add')"
            variant="primary"
            class="btn-icon mt-15"
            @click="allocateUnitToOperator()"
          >
            <feather-icon icon="PlusIcon" size="12" />
            {{ $t("operators.Add") }}
          </b-button>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mb-64 mtt-50" v-if="!isCreate">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="updateUnitAllocation()"
        >
          {{ $t("operators.Save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="mt-2"
          @click="onCloseUnitModal()"
        >
          {{ $t("user.Cancel") }}
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  VBTooltip,
  BSkeletonTable,
  BButton,
  BFormGroup,
  BCardText
} from "bootstrap-vue";

import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import UnitService from "@/libs/api/unit-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import * as moment from "moment";
import constants from "@/utils/constants";

import DateTimePicker from "../DynamicComponents/DateWithTimePicker.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BPagination,
    AreYouSureModal,
    VBTooltip,
    BSkeletonTable,
    DynamicBadge,
    vSelect,
    VBTooltip,
    BButton,
    BFormGroup,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    DateTimePicker
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      start_date: this.getCurrentTime(),
      end_date: null,
      name: "",
      unit_id: null,
      minTime: null,
      endDateDisabled: null,
      unitList: [],
      id: null
    };
  },
  props: [
    "isCreate",
    "groupName",
    "perPage",
    "unitAllocationShow",
    "onCloseUnitModal",
    "allocationData",
    "getAllocatedUnitsByOperators"
  ],
  mounted() {
    if (this.allocationData && this.allocationData.id) {
      this.start_date = this.allocationData.start_date
        ? this.allocationData.start_date
        : "";
      // this.end_date = this.allocationData.end_date
      //   ? this.DateTimeConvert(this.allocationData.end_date)
      //   : "";
      this.unit_id = this.allocationData.unit_id;
      this.name = this.allocationData.name;
      this.id = this.allocationData.id;
    }

    this.getAllUnitList();
  },

  methods: {
    formattedDates(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    DateTimeConvert(date) {
      let formattedDate = date ? date : this.getCurrentTime();
      return formattedDate;
    },

    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.unitAllocationRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    async updateUnitAllocation(row) {
      let message, title;
      try {
        await this.validationForm();

        if (this.start_date && this.end_date) {
          const startTime = moment(this.start_date);
          const endTime = moment(this.end_date);

          if (startTime.isSameOrAfter(endTime)) {
            throw {
              message: this.$t("unit.EndTimeGreaterThanStartTime")
            };
          }
        }

        this.show = true;

        let requestData = {
          id: this.id,
          operator_id: this.$route.params.id,
          unit_id: this.unit_id,
          start_date: this.start_date
            ? this.convertDatePerTimezone(this.start_date)
            : null,
          end_date: this.end_date
            ? this.convertDatePerTimezone(this.end_date)
            : null
        };

        let response = await new UnitService().updateUnitAllocation(
          requestData
        );
        if (response && response.data) {
          await this.getAllocatedUnitsByOperators();
          this.show = false;
          title = this.$t("operators.OperatorUnitAllocationUpdatedTitle");
          message = this.$t("operators.OperatorUnitAllocationUpdatedTitleMsg");

          this.$toast({
            component: ToastificationContent,
            props: {
              title: title,
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
      // Perform validation or other operations before saving
      this.onCloseUnitModal();
    },
    closeUnitAllocate() {
      this.end_date = null;
    },
    async getAllUnitList() {
      try {
        this.showLoading = true;
        let response = await new UnitService().getAllUnits({
          page: 1,
          page_size: 50,
          selfAllocated: true
        });
        this.showLoading = false;
        if (response && response.data) {
          this.unitList = response.data.units;
          this.unitList = [
            ...new Map(this.unitList.map((item) => [item["id"], item])).values()
          ];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async allocateUnitToOperator() {
      let message, title;
      try {
        await this.validationForm();
        if (this.start_date && this.end_date) {
          const startTime = moment(this.start_date);
          const endTime = moment(this.end_date);
          if (startTime.isSameOrAfter(endTime)) {
            throw {
              message: this.$t("unit.EndTimeGreaterThanStartTime")
            };
          }
        }
        this.show = true;
        let requestData = {
          operator_id: this.$route.params.id,
          unit_id: this.unit_id,
          start_date: this.start_date
            ? this.convertDatePerTimezone(this.start_date)
            : null,
          end_date: this.end_date
            ? this.convertDatePerTimezone(this.end_date)
            : null
        };
        this.end_date = null;

        let response = await new UnitService().allocateUnitToOperator(
          requestData
        );
        if (response && response.data) {
          await this.getAllocatedUnitsByOperators();
          this.end_date = null;
          // this.start_date = this.start_date;
          // this.$refs.flatpickerEndDate.fp.clear();
          this.$refs.unitSelect.clearSelection();
          this.$refs.clearErrorMessage.reset();
          this.closeUnitAllocate();
          this.show = false;
          title = this.$t("operators.UnitAllocatedTitle");
          message = this.$t("operators.UnitAllocatedMsg");

          this.$toast({
            component: ToastificationContent,
            props: {
              title: title,
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          // this.start_date = this.DateTimeConvert();
          // this.$refs.flatpickerEndDate.fp.clear();
          this.$refs.unitSelect.clearSelection();
          this.$refs.clearErrorMessage.reset();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        console.log(err);
        this.show = false;
      }
    }
  }
};
</script>
<style lang="scss">
.selectunittext {
  .vs__selected-options {
    max-width: 87% !important;
    .vs__selected {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 30px) !important;
      display: block !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.unit-allocate-form {
  .select-unit-un {
    position: relative;
  }
  .text-danger {
    position: absolute;
  }
}
.allocate-form-outer{
  .mtt-50 {
    margin-top: 0px;
  }
  .mb-64 {
    margin-bottom: 0px;
    .mt-2,.my-2 {
      margin-top: 0.5rem !important;
    }
  }
}
.modal-body {
  .modal-view {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
@media screen and (max-width: 667px) {
  .modal-body {
    .modal-view {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
<style lang="scss">
.new-date-picker-25 {
  .daterangepicker.ltr.show-calendar.single.openscenter.linked {
    left: 0 !important;
    transform: translate(0%) !important;
  }
}
</style>
