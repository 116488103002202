<template>
  <div class="create-operator-ui">
    <b-card class="under-line-title">
      <div class="mb-2">
        <div>
          <span class="add-operator" v-if="$route.name === 'create-operator'">
            {{ $t("operators.AddOperator") }}</span
          >
          <span class="add-operator" v-if="$route.name === 'edit-operator'">
            {{ $t("operators.UpdateOperators") }}</span
          >
          <span class="add-operator" v-if="$route.name === 'view-operator'">
            {{ $t("operators.ViewOperators") }}
          </span>
        </div>
        <div class="text-right">
          <div>
            <b-button
              variant="outline-primary"
              class="v2-back"
              @click="redirectList()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="add-operator-scroll">
        <validation-observer ref="operatorRules" #default="{}">
          <b-form>
            <div>
              <b-row class="index-1">
                <b-col sm="6">
                  <b-form-group :label="$t('operators.Name')">
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      vid="Name"
                      :rules="{
                        required: true,
                        min: constants.MIN_ACC_NAME,
                        max: constants.MAX_ACC_NAME
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="name"
                          v-model="operator.name"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="name"
                          :placeholder="$t('operators.Name')"
                          :disabled="!isEditable"
                          :class="{
                            disabled: !isEditable,
                            'is-valid': errors.length === 0 && operator.name
                          }"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group :label="$t('operators.Code')">
                    <validation-provider
                      #default="{ errors }"
                      name="Code"
                      vid="Code"
                      :rules="{
                        required: false,
                        min: constants.MIN_OPERATOR_CODE,
                        max: constants.MAX_OPERATOR_CODE
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="name"
                          v-model="operator.code"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="name"
                          :placeholder="$t('operators.Code')"
                          :disabled="!isEditable"
                          :class="!isEditable ? 'disabled' : ''"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="6" class="input-ui-design">
                  <b-form-group :label="$t('profile.PhoneNumber')">
                    <validation-provider name="mobile">
                      <VuePhoneNumberInput
                        @update="countryChanged"
                        v-model="operator.mobile"
                        :default-country-code="countryCode"
                        id="vue-select"
                        name="phoneNumber"
                        :class="
                          isMobileValid && operator.mobile ? 'valid-phone' : ''
                        "
                        :clearable="false"
                        :placeholder="$t('user.EnterMobile')"
                      />
                      <!-- <small class="text-danger">{{
                   errors[0] ? handleError(errors[0]) : ""
                  }}</small> -->
                      <div class="hassErrorcss">
                        <small class="text-danger">{{
                          hasError && operator.mobile ? hasError : ""
                        }}</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col lg="6" class="input-ui-design">
              <b-form-group :label="$t('operators.MobileNumber')">
                <validation-provider
                  name="Mobile"
                  :rules="{
                    required: false
                  }"
                >
                  <VuePhoneNumberInput
                    @update="countryChanged"
                    v-model="mobileNo"
                    :default-country-code="countryCode"
                    :disabled="!isEditable"
                    :class="!isEditable ? 'disabled' : ''"
                  />
                  <small class="text-danger">{{ hasError }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
                <b-col sm="6">
                  <b-form-group
                    :label="
                      operator.isLoggedIn
                        ? $t('operators.LoginEmail')
                        : $t('operators.Email')
                    "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      vid="email"
                      :rules="{
                        required: operator.isLoggedIn ? true : false,
                        email: operator.isLoggedIn ? true : false
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="email"
                          v-model="operator.email"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="name"
                          @input="
                            () => {
                              operator.email = operator.email.toLowerCase();
                            }
                          "
                          :placeholder="$t('user.EnterEmail')"
                          :class="
                            !isEditable || !operator.isLoggedIn
                              ? 'disabled'
                              : ''
                          "
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="index-2">
                <b-col
                  sm="6"
                  v-if="
                    checkAbility({
                      action: constants.PERMISSIONS_ACTION.READ,
                      subject: constants.PERMISSIONS_MODEL.LABELS
                    })
                  "
                >
                  <b-form-group :label="$t('operators.Labels')">
                    <TagMultiSelect
                      v-model="operator.labels"
                      :disabled="!isEditable"
                      :class="!isEditable ? 'disabled' : ''"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="3"
                  class="login-account-height"
                  v-if="
                    ($route.name === 'create-operator' ||
                      $route.name === 'edit-operator' ||
                      $route.name === 'view-operator') &&
                    !isUnLinkedUser
                  "
                >
                  <b-form-group>
                    <b-form-checkbox
                      :class="!isEditable ? 'disabled' : ''"
                      v-model="operator.isLoggedIn"
                      class="login-account-top"
                      >{{ $t("operators.CreateLoginAccount") }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  class="d-flex align-items-center operator-status create-operator-h"
                  v-if="$route.name != 'create-operator' && isUnLinkedUser"
                >
                  <b-form-group class="ml-15 operator-status-css">
                    <div class="disable-check">
                      <div v-if="operator.status == 'INVITED'">
                        {{ operator.status }}
                      </div>
                      <b-form-checkbox
                        v-model="isLinkedstatus"
                        :disabled="!isEditable"
                        :class="!isEditable ? 'disabled' : ''"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        v-if="operator.status != 'INVITED'"
                        @input="handleInput"
                      >
                        <h6>{{ userAccountStatus }}</h6>
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                  <div
                    class="d-flex align-items-center operator-h"
                    v-if="operator.status === 'INVITED'"
                  >
                    <b-button
                      :disabled="!isEditable"
                      :class="!isEditable ? 'disabled' : ''"
                      v-b-tooltip.hover.v-primary
                      :title="$t('tooTip.resend')"
                      variant="primary"
                      size="sm"
                      class="btn-icon"
                      v-if="$route.name != 'create-operator'"
                      @click="resend()"
                    >
                      <feather-icon
                        icon="RefreshCwIcon"
                        size="18"
                        style="cursor: pointer"
                        class="mr-0 mr-sm-50 mr-101"
                      />
                      <span> {{ $t("operators.ResendInvitation") }}</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col v-if="$route.name === 'create-operator'">
                  <b-button
                    @click="upsertOperator"
                    variant="primary"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mt-2 mr-1"
                    :disabled="
                      invalid ||
                      (!invalid && operator.isLoggedIn && !operator.email) ||
                      (!isMobileValid && operator.mobile ? true : false)
                    "
                  >
                    {{ $t("profile.SaveChanges") }}
                  </b-button>
                </b-col>
              </b-row> -->
            </div>
          </b-form>
        </validation-observer>

        <div
          class="under-line-title page-header-area"
          v-if="
            $route.name === 'view-operator' || $route.name === 'edit-operator'
          "
        >
          <AllocatedUnits />
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div>
          <b-button
            @click="upsertOperator"
            variant="primary"
            class="btn-icon"
            v-if="$route.name === 'edit-operator'"
          >
            <!-- <feather-icon icon="PlusIcon" size="16" /> -->

            <feather-icon icon="SaveIcon" size="16" class="mr-101 mr-sm-50" />
            <span>{{ $t("operators.UpdateOperators") }}</span>
          </b-button>
          <b-button
            v-if="$route.name === 'create-operator'"
            @click="upsertOperator"
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-1"
            :disabled="
              invalid ||
              (!invalid && operator.isLoggedIn && !operator.email) ||
              (!isMobileValid && operator.mobile ? true : false)
            "
          >
            {{ $t("profile.SaveChanges") }}
          </b-button>
        </div>
      </div>
      <Loader :show="show" />
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BLink,
  VBTooltip
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import constants from "@/utils/constants";
import Loader from "@/layouts/components/Loader.vue";
import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { phone } from "phone";
import AllocatedUnits from "@/views/Operators/AllocatedUnits.vue";
import UnitService from "@/libs/api/unit-service";
import AccountService from "@/libs/api/account-service";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BCard,
    BInputGroup,
    BButton,
    Loader,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormInvalidFeedback,
    BFormCheckbox,
    TagMultiSelect,
    VuePhoneNumberInput,
    AllocatedUnits,
    BLink,
    VBTooltip
  },
  data() {
    return {
      countryCallingCode: "+44",
      isMobileValid: true,
      mobileNo: "",
      countryCode: "",
      show: false,
      constants,
      isEditable: true,
      operator: {
        name: "",
        code: "",
        mobile: "",
        email: "",
        isLoggedIn: false,
        labels: [],
        status: "",
        actionStatus: ""
      },
      isUnLinkedUser: false,
      hasError: "",
      operatorRole: "",
      isLinkedstatus: false,
      userAccountStatus: ""
    };
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  mounted() {
    if (
      this.$route.name === "edit-operator" ||
      this.$route.name === "view-operator"
    ) {
      if (this.$route.params.id) {
        this.getOperator(this.$route.params.id);
      } else {
        this.$router.go(-1);
      }
    }
  },
  computed() {},
  watch: {
    isEditable(val) {
      this.isEditable = val;
    }
  },
  methods: {
    handleInput(newValue) {
      if (newValue !== this.operator.status) {
        this.changeStatus(newValue);
      }
    },
    changeStatus(newValue) {
      if (this.operator.status == "LINKED") {
        this.operator.actionStatus = "UNLINK";
      } else {
        this.operator.actionStatus = "LINK";
      }
      if (newValue) {
        this.userAccountStatus = this.$t("operators.UnLinkedAccountStatus");
        this.operator.isLoggedIn = true;
      } else {
        this.userAccountStatus = this.$t("operators.LinkedAccountStatus");
        this.operator.isLoggedIn = false;
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.operatorRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    async resend() {
      try {
        this.show = true;
        const body = {
          email: this.operator.email,
          role: this.operatorRole,
          is_resend: true
        };
        let response = await new AccountService().inviteUser(body);
        if (response && response.data) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("user.InvitationSentTitle"),
              text: this.$t("user.InvitationReSent"),
              icon: "EditIcon",
              variant: "success"
            }
          });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    redirectList() {
      //this.$router.push({ name: "operator-list" });
      this.$router.go(-1);
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    async upsertOperator() {
      let message, title;
      try {
        await this.validationForm();
        this.show = true;
        if (this.operator.mobile) {
          let phoneNumber = this.operator.mobile;
          phoneNumber = phoneNumber.replace(/[\s\(\)-]/g, "");
          phoneNumber = phoneNumber.replace(/[\(\)-]/g, "");
          this.operator.mobile = this.countryCallingCode + "" + phoneNumber;
        }
        const isValidateLabels =
          this.operator.labels &&
          this.operator.labels.every(
            (item) => typeof item === "object" && item !== null
          );

        if (isValidateLabels) {
          const me = this;
          me.operator.labels =
            me.operator &&
            me.operator.labels &&
            me.operator.labels.map((label) => label && label.name);
          me.operator.labels = me.operator.labels.filter(
            (label) => label !== "" || label !== null
          );
        }
        this.operator.labels = this.operator.labels.filter(
          (label) => label !== "" || label !== null
        );

        let requestData = {},
          response = null;
        if (this.$route.params.id) {
          requestData = {
            id: this.$route.params.id,
            name: this.operator.name,
            code: this.operator.code,
            mobile: this.operator.mobile ? this.operator.mobile : "",
            email: this.operator.email ? this.operator.email : "",
            link_user_acc: this.operator.isLoggedIn
              ? this.operator.isLoggedIn
              : false,
            labels: this.operator.labels,
            action_status: this.operator.actionStatus
              ? this.operator.actionStatus
              : this.operator.isLoggedIn
              ? "LINK"
              : "",
            status: this.operator.status
          };
          response = await new UnitService().updateOperator(requestData);
        } else {
          requestData = {
            name: this.operator.name,
            code: this.operator.code,
            mobile: this.operator.mobile ? this.operator.mobile : "",
            email:
              this.operator.isLoggedIn && this.operator.email
                ? this.operator.email
                : "",
            link_user_acc: this.operator.isLoggedIn
              ? this.operator.isLoggedIn
              : false,
            labels: this.operator.labels,
            action_status: "LINK",
            status: ""
          };
          response = await new UnitService().createOperator(requestData);
        }
        // let response = await new UnitService().upsertOperator(requestData);
        if (response && response.data) {
          this.show = false;
          title = this.$t("operators.OperatorCreatedTitle");
          message = this.$t("operators.OperatorCreatedMsg");
          if (this.operator && this.operator.id) {
            title = this.$t("operators.OperatorUpdatedTitle");
            message = this.$t("operators.OperatorUpdatedMsg");
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: title,
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });

          this.$router.push({ name: "operator-list" });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    countryChanged(e) {
      let me = this;
      let hasErr = "";
      if (!this.operator.mobile) {
        return;
      }
      if (e.formattedNumber) {
        const mobileDetailsCheck = phone(e.formattedNumber);
        if (!mobileDetailsCheck || !mobileDetailsCheck.countryIso2) {
          me.hasError = me.$t("errors.invalidMobile");
          this.isMobileValid = false;

          return;
        }
        const mobileDetails = phone(e.formattedNumber, {
          country: mobileDetailsCheck.countryIso2
        });
        this.selectedCountry =
          (mobileDetails && mobileDetails.countryIso2) || null;
        this.countryCallingCode = mobileDetails && mobileDetails.countryCode;
        if (
          !mobileDetails ||
          !mobileDetails.isValid ||
          !mobileDetails.countryCode
        ) {
          this.isMobileValid = false;
          hasErr = me.$t("errors.invalidMobile");
        }
        if (mobileDetails.isValid) {
          this.isMobileValid = true;
        } else {
          this.isMobileValid = false;
        }
      }
      this.operator.mobile = e.formattedNumber;
      if ((hasErr && hasErr !== "") || !this.operator.mobile) {
        if (!this.operator.mobile) {
          hasErr = this.$t("profile.MobileRequired");
        }
        me.hasError = hasErr;
        this.isMobileValid = false;
        return;
      } else {
        me.hasError = "";
        this.isMobileValid = true;
        return;
      }
    },
    async getOperator(id) {
      try {
        this.show = true;
        if (
          this.$route.name == "view-operator" &&
          this.$route.params &&
          this.$route.params.id
        ) {
          this.isEditable = false;
        }
        let response = await new UnitService().getOperator({
          id
        });

        if (response && response.data) {
          this.show = false;
          this.operator = response.data || {};
          this.operatorRole = response.data.role.id;
          if (response.data.status == "LINKED") {
            this.userAccountStatus = this.$t("operators.UnLinkedAccountStatus");
            this.operator.isLoggedIn = true;
            this.isLinkedstatus = true;
          } else if (response.data.status == "UNLINKED") {
            this.userAccountStatus = this.$t("operators.LinkedAccountStatus");
            this.isLinkedstatus = false;
          }
          if (response.data.email) {
            this.isUnLinkedUser = true;
          } else {
            this.isUnLinkedUser = false;
          }
          const mobileDetailsCheck = phone(this.operator.mobile);
          if (mobileDetailsCheck && mobileDetailsCheck.countryIso2) {
            this.countryCode = mobileDetailsCheck.countryIso2 || "IN";
            this.mobileNo = mobileDetailsCheck.phoneNumber.replace(
              mobileDetailsCheck.countryCode,
              ""
            );
          }
          this.$route.meta.breadcrumb = [
            ...constants.OPERATOR_ROUTE_META_BREADCRUMB
          ];
          if (this.$route.name === "edit-operator") {
            this.$route.meta.breadcrumb.push(
              ...[
                {
                  to: {
                    name: "edit-operator",
                    params: { id: response.data.id }
                  },
                  text: response.data.name
                },
                {
                  text: "Breadcrumb_Edit",
                  active: true
                }
              ]
            );
          } else {
            this.$route.meta.breadcrumb.push({
              text: response.data.name
            });
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
.index-1 {
  z-index: 2;
  position: relative;
}
.index-2 {
  z-index: 1;
  position: relative;
}
.add-operator {
  position: absolute;
  width: 185px;
  height: 22px;
  font-weight: 500;
  font-size: 17.99px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.14px;
  color: #5e5873;
}
.update-operator {
  position: absolute;
  width: 175px;
  height: 22px;
  display: flex;
  align-items: center;
  color: var(--primary);
}
.custom-card .card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.operator-status {
  .form-group {
    margin-bottom: 0px;
    margin-top: 10px;
  }
}
.page-header-area {
  .header-area {
    padding: 1rem;
  }
}
.login-account-top {
  margin-top: 30px;
}
.login-account-height {
  height: 79px;
}
@media screen and (max-width: 600px) {
  .login-account-top {
    margin-top: 5px;
  }
  .login-account-height {
    height: 29px;
  }
}
.create-operator-h {
  height: 79px;
}
.operator-status-css {
  top: 30px;
}
.operator-h {
  height: 79px;
}
.mr-101 {
  margin-right: 5px;
}
</style>
<style lang="scss" scoped>
.add-operator-scroll {
  max-height: calc(100vh - 250px) !important;
  min-height: calc(100vh - 250px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.create-operator-ui {
  .card-body {
    min-height: calc(100vh - 135px) !important;
  }
}
@media only screen and (max-width: 767px) {
  .card-body {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    min-height: calc(100vh - 155px) !important;
  }
}
</style>
