var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-operator-ui"},[_c('b-card',{staticClass:"under-line-title"},[_c('div',{staticClass:"mb-2"},[_c('div',[(_vm.$route.name === 'create-operator')?_c('span',{staticClass:"add-operator"},[_vm._v(" "+_vm._s(_vm.$t("operators.AddOperator")))]):_vm._e(),(_vm.$route.name === 'edit-operator')?_c('span',{staticClass:"add-operator"},[_vm._v(" "+_vm._s(_vm.$t("operators.UpdateOperators")))]):_vm._e(),(_vm.$route.name === 'view-operator')?_c('span',{staticClass:"add-operator"},[_vm._v(" "+_vm._s(_vm.$t("operators.ViewOperators"))+" ")]):_vm._e()]),_c('div',{staticClass:"text-right"},[_c('div',[_c('b-button',{staticClass:"v2-back",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.redirectList()}}},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"ArrowLeftIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("role.Back")))])],1)],1)])]),_c('div',{staticClass:"add-operator-scroll"},[_c('validation-observer',{ref:"operatorRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',[_c('div',[_c('b-row',{staticClass:"index-1"},[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('operators.Name')}},[_c('validation-provider',{attrs:{"name":"Name","vid":"Name","rules":{
                      required: true,
                      min: _vm.constants.MIN_ACC_NAME,
                      max: _vm.constants.MAX_ACC_NAME
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",class:{
                          disabled: !_vm.isEditable,
                          'is-valid': errors.length === 0 && _vm.operator.name
                        },attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"name","placeholder":_vm.$t('operators.Name'),"disabled":!_vm.isEditable},model:{value:(_vm.operator.name),callback:function ($$v) {_vm.$set(_vm.operator, "name", $$v)},expression:"operator.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('operators.Code')}},[_c('validation-provider',{attrs:{"name":"Code","vid":"Code","rules":{
                      required: false,
                      min: _vm.constants.MIN_OPERATOR_CODE,
                      max: _vm.constants.MAX_OPERATOR_CODE
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",class:!_vm.isEditable ? 'disabled' : '',attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"name","placeholder":_vm.$t('operators.Code'),"disabled":!_vm.isEditable},model:{value:(_vm.operator.code),callback:function ($$v) {_vm.$set(_vm.operator, "code", $$v)},expression:"operator.code"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"input-ui-design",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('profile.PhoneNumber')}},[_c('validation-provider',{attrs:{"name":"mobile"}},[_c('VuePhoneNumberInput',{class:_vm.isMobileValid && _vm.operator.mobile ? 'valid-phone' : '',attrs:{"default-country-code":_vm.countryCode,"id":"vue-select","name":"phoneNumber","clearable":false,"placeholder":_vm.$t('user.EnterMobile')},on:{"update":_vm.countryChanged},model:{value:(_vm.operator.mobile),callback:function ($$v) {_vm.$set(_vm.operator, "mobile", $$v)},expression:"operator.mobile"}}),_c('div',{staticClass:"hassErrorcss"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.hasError && _vm.operator.mobile ? _vm.hasError : ""))])])],1)],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.operator.isLoggedIn
                      ? _vm.$t('operators.LoginEmail')
                      : _vm.$t('operators.Email')}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":{
                      required: _vm.operator.isLoggedIn ? true : false,
                      email: _vm.operator.isLoggedIn ? true : false
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",class:!_vm.isEditable || !_vm.operator.isLoggedIn
                            ? 'disabled'
                            : '',attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"name","placeholder":_vm.$t('user.EnterEmail')},on:{"input":function () {
                            _vm.operator.email = _vm.operator.email.toLowerCase();
                          }},model:{value:(_vm.operator.email),callback:function ($$v) {_vm.$set(_vm.operator, "email", $$v)},expression:"operator.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"index-2"},[(
                  _vm.checkAbility({
                    action: _vm.constants.PERMISSIONS_ACTION.READ,
                    subject: _vm.constants.PERMISSIONS_MODEL.LABELS
                  })
                )?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('operators.Labels')}},[_c('TagMultiSelect',{class:!_vm.isEditable ? 'disabled' : '',attrs:{"disabled":!_vm.isEditable},model:{value:(_vm.operator.labels),callback:function ($$v) {_vm.$set(_vm.operator, "labels", $$v)},expression:"operator.labels"}})],1)],1):_vm._e(),(
                  (_vm.$route.name === 'create-operator' ||
                    _vm.$route.name === 'edit-operator' ||
                    _vm.$route.name === 'view-operator') &&
                  !_vm.isUnLinkedUser
                )?_c('b-col',{staticClass:"login-account-height",attrs:{"sm":"3"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"login-account-top",class:!_vm.isEditable ? 'disabled' : '',model:{value:(_vm.operator.isLoggedIn),callback:function ($$v) {_vm.$set(_vm.operator, "isLoggedIn", $$v)},expression:"operator.isLoggedIn"}},[_vm._v(_vm._s(_vm.$t("operators.CreateLoginAccount"))+" ")])],1)],1):_vm._e(),(_vm.$route.name != 'create-operator' && _vm.isUnLinkedUser)?_c('b-col',{staticClass:"d-flex align-items-center operator-status create-operator-h",attrs:{"sm":"6"}},[_c('b-form-group',{staticClass:"ml-15 operator-status-css"},[_c('div',{staticClass:"disable-check"},[(_vm.operator.status == 'INVITED')?_c('div',[_vm._v(" "+_vm._s(_vm.operator.status)+" ")]):_vm._e(),(_vm.operator.status != 'INVITED')?_c('b-form-checkbox',{class:!_vm.isEditable ? 'disabled' : '',attrs:{"disabled":!_vm.isEditable,"checked":"true","name":"check-button","switch":"","inline":""},on:{"input":_vm.handleInput},model:{value:(_vm.isLinkedstatus),callback:function ($$v) {_vm.isLinkedstatus=$$v},expression:"isLinkedstatus"}},[_c('h6',[_vm._v(_vm._s(_vm.userAccountStatus))])]):_vm._e()],1)]),(_vm.operator.status === 'INVITED')?_c('div',{staticClass:"d-flex align-items-center operator-h"},[(_vm.$route.name != 'create-operator')?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"btn-icon",class:!_vm.isEditable ? 'disabled' : '',attrs:{"disabled":!_vm.isEditable,"title":_vm.$t('tooTip.resend'),"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.resend()}}},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50 mr-101",staticStyle:{"cursor":"pointer"},attrs:{"icon":"RefreshCwIcon","size":"18"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("operators.ResendInvitation")))])],1):_vm._e()],1):_vm._e()],1):_vm._e()],1)],1)])]}}])}),(
          _vm.$route.name === 'view-operator' || _vm.$route.name === 'edit-operator'
        )?_c('div',{staticClass:"under-line-title page-header-area"},[_c('AllocatedUnits')],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',[(_vm.$route.name === 'edit-operator')?_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"primary"},on:{"click":_vm.upsertOperator}},[_c('feather-icon',{staticClass:"mr-101 mr-sm-50",attrs:{"icon":"SaveIcon","size":"16"}}),_c('span',[_vm._v(_vm._s(_vm.$t("operators.UpdateOperators")))])],1):_vm._e(),(_vm.$route.name === 'create-operator')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"variant":"primary","disabled":_vm.invalid ||
            (!_vm.invalid && _vm.operator.isLoggedIn && !_vm.operator.email) ||
            (!_vm.isMobileValid && _vm.operator.mobile ? true : false)},on:{"click":_vm.upsertOperator}},[_vm._v(" "+_vm._s(_vm.$t("profile.SaveChanges"))+" ")]):_vm._e()],1)]),_c('Loader',{attrs:{"show":_vm.show}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }